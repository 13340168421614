<template>
  <v-container fluid>
    <h3 class="page-title d-flex justify-space-between align-center">
      庫存異動紀錄
    </h3>
    <v-row align="center">
      <v-col cols="9" md="3">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formattedDate"
              label="日期區間"
              outlined
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            range
            no-title
            scrollable
            locale="zh-tw"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> 取消 </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)">
              確定
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3" sm="auto" md="1">
        <v-btn color="primary" @click="search">
          <span class="d-none d-md-inline">查詢</span>
          <v-icon class="d-md-none">mdi-magnify</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h4>篩選條件</h4>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="6" sm="3" md="2">
        <v-text-field
          outlined
          dense
          hide-details
          label="關鍵字(商品、操作者)"
          v-model="keyword"
          clearable
        ></v-text-field>
      </v-col>
      <v-col cols="6" sm="3" md="2">
        <v-select
          label="動作類別"
          v-model="filter.type"
          :items="types"
          outlined
          dense
          hide-details
        >
        </v-select>
      </v-col>
      <v-col cols="6" sm="3" md="2">
        <v-autocomplete
          label="主店點/出貨點"
          v-model="filter.from"
          :items="branches.from"
          outlined
          dense
          hide-details
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="6" sm="3" md="2">
        <v-autocomplete
          label="收貨點"
          v-model="filter.to"
          :items="branches.to"
          outlined
          dense
          hide-details
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :mobile-breakpoint="0"
          :items="filterTransactions"
          :items-per-page="10"
          :footer-props="{
            itemsPerPageOptions: [20, 50, 100],
            showCurrentPage: true,
            showFirstLastPage: true,
          }"
        >
          <template v-slot:item.to="{ item }">
            {{ item.to || "---" }}
          </template>
          <template v-slot:item.amount="{ item }">
            {{ formatPrice(item.amount) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import dayjs from "dayjs";
import util from "@/mixins/util";

export default {
  name: "TransactionHistory",
  mixins: [util],
  data: () => ({
    menu: false,
    transactions: [],
    date: [],
    keyword: "",
    filter: {
      type: null,
      to: "",
      from: "",
    },
    types: [],
    branches: {
      from: null,
      to: null,
    },
    headers: [
      { text: "商品名稱", value: "product" },
      { text: "類別", value: "type", align: "center", sortable: false },
      { text: "主店點/出貨", value: "from", align: "center", sortable: false },
      { text: "收貨", value: "to", align: "center", sortable: false },
      { text: "交易量", value: "amount", align: "right", sortable: false },
      {
        text: "備註",
        value: "comment",
        align: "left",
        sortable: false,
        cellClass: "cell--wrap",
      },
      { text: "操作時間", value: "createdAt", align: "center" },
      { text: "操作者", value: "user", align: "center", sortable: false },
    ],
  }),
  computed: {
    _: () => _,
    formattedDate() {
      return this.date[0] && this.date[1]
        ? dayjs(this.date[0]).format("YYYY-MM-DD") +
            " ~ " +
            dayjs(this.date[1]).format("YYYY-MM-DD")
        : "";
    },
    filterTransactions() {
      let transactions = [...this.transactions];
      if (this.keyword) {
        transactions = transactions.filter(
          (transaction) =>
            transaction.product
              ?.toUpperCase()
              .includes(this.keyword.toUpperCase()) ||
            transaction.user?.toUpperCase().includes(this.keyword.toUpperCase())
        );
      }
      if (this.filter.type) {
        transactions = transactions.filter(
          (transaction) => transaction.type === this.filter.type
        );
      }
      if (this.filter.from) {
        transactions = transactions.filter(
          (transaction) => transaction.from === this.filter.from
        );
      }
      if (this.filter.to) {
        transactions = transactions.filter(
          (transaction) => transaction.to === this.filter.to
        );
      }

      return transactions;
    },
  },
  async created() {
    this.date[0] = dayjs().format("YYYY-MM-01");
    this.date[1] = dayjs().endOf("month").format("YYYY-MM-DD");
  },
  methods: {
    async search() {
      if (!this.date) {
        return false;
      }
      this.$vloading.show();

      try {
        let { data: products } = await this.axios.get(`/product`);
        products = products.filter((o) => o.name);
        let { data } = await this.axios.get(`/transaction`, {
          params: {
            dateRange: this.date,
          },
        });

        let transactions = [...data].sort((a, b) => {
          const aTime = a.createdAt;
          const bTime = b.createdAt;
          if (dayjs(aTime).isAfter(dayjs(bTime))) {
            return -1;
          }
          if (dayjs(aTime).isBefore(dayjs(bTime))) {
            return 1;
          }
          return 0;
        });

        transactions = transactions
          .map((transaction) => {
            if (transaction.type === "進貨" && transaction.from === null) {
              return { ...transaction, from: "外部採購" };
            }
            return transaction;
          })
          .map((transaction) => {
            if (transaction.to === null) {
              return { ...transaction, to: "" };
            }
            return transaction;
          })
          .map((transaction) => {
            const product = products.find(
              (product) => product._id === transaction.productId
            );
            return {
              ...transaction,
              product: product?.name ?? null,
            };
          });
        this.transactions = transactions;

        this.branches.to = Object.keys(_.groupBy(transactions, "to"));
        this.branches.to.unshift({ text: "全選", value: "" });

        this.branches.from = Object.keys(_.groupBy(transactions, "from"));
        this.branches.from.unshift({ text: "全選", value: "" });

        this.types = Object.keys(_.groupBy(transactions, "type"));
        this.types.unshift({ text: "全選", value: "" });
      } catch (error) {
        console.log(error);
      }
      this.$vloading.hide();
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .cell {
  &--wrap {
    max-width: 200px;
  }
}
</style>
